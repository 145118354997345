import React from 'react'
import {useState} from 'react'
import { FaShoppingCart } from "react-icons/fa";
import {Basket} from "./basket";
import {Link} from 'react-router-dom'

const showBasket = (props) => {
    let summa=0
    props.basket.forEach(el=>summa+=Number.parseFloat(el.price)*Number.parseInt(el.qty))
    return (
        <div>
    { props.basket.map( el => (
        <Basket onDelete={props.onDelete} onChangeQty={props.onChangeQty} key={el.id} item={el} />
    ) ) }
        <p className='summa'>Итого: { new Intl.NumberFormat().format(summa)} ₽</p>
        </div>)
}

const showNothing = () => {
    return (
        <div className='empty'><h3>Корзина пуста</h3></div>
    )
}

export default function Header( props ) {
    let [cartOpen, setCartOpen] = useState(false)
    return(
        <header>
            <div>
                <span className='logo'><Link title='На главную' class="link" to="/">АудиоМодуль</Link></span>
                <ul className='navigate' >
                    <li><Link to="/about">О сайте</Link></li>
                    <li><Link to="/Contacts">Контакты</Link></li>
                </ul>
                <FaShoppingCart title="Корзина" onClick={() => setCartOpen(cartOpen= !cartOpen)} className={ 'shop-cart-button'} />
                {cartOpen && (
                 <div className="shop-cart" >
                     {props.basket.length>0 ? showBasket(props) : showNothing() }
                 </div>
                )}
            </div>
            <div className='presentation'></div>
        </header>    )
}