import React, {Component} from 'react'

export class Item extends Component {
    render() {
        return(
            <div className='item'>
                <img src={'./img/'+this.props.item.img} onClick={ () => this.props.onShowItem(this.props.item)}/>
                <h3 onClick={ () => this.props.onShowItem(this.props.item)}>{this.props.item.title}</h3>
                <p>{this.props.item.desc}</p>
                <b>{new Intl.NumberFormat().format(this.props.item.price) } ₽</b>
                {/*
                <div className='addToBasket' onClick={() => this.props.onAdd( this.props.item ) }><FaCheck  alt='Добавить в корзину'/><FaShoppingCart /></div>
                */}
                { this.props.basketBottom(this.props.item)}
            </div>
        );
    }
}