
import React, {Component} from 'react'
import {Item} from "./item";

 export class Items extends Component{
    render() {
        return(
            <main>
                { this.props.items.map(el=>(
                    <Item item={el} onShowItem={this.props.onShowItem} basketBottom={this.props.basketBottom}/>
                    //<Item item={el} onAdd={this.props.onAdd} onShowItem={this.props.onShowItem} basketBottom={this.props.basketBottom}/>

                    // <Item key={el.id} item={el} onAdd={this.props.onAdd}/>
                        ) ) }
            </main>
        )
    }
 }

 export default Items