import React, {Component} from 'react'

export class Categories extends Component {
    constructor(props) {
        super(props)
        this.state={
            categories: [
                { key: 'all',
                  name: 'Все'
                },
                { key: 'bp',
                    name: 'Блок питания'
                },
                { key: 'ctrl',
                    name: 'Контроллер'
                },
                { key: 'vc',
                    name: 'Регулятор громкости'
                },
                { key: 'sel',
                    name: 'Селектор'
                },
                { key: 'pr',
                    name: 'Защита АС'
                },
                { key: 'pa',
                    name: 'УМЗЧ'
                },
                { key: 'pu',
                    name: 'ПУ'
                }
            ]
        }
    }
  render() {
      return(
          <div >
              {this.state.categories.map(el => (
                  <div className={ this.props.category===el.key ? 'categorisel' : 'categories'}  key={el.key} onClick={() => this.props.choseCategory(el.key)}>{el.name}</div>
              ))}

              {console.log('cat ' + this.props.category)}

          </div>
      )
    }
}