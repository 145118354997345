import React, {Component} from 'react'
import NumericInput from 'react-numeric-input';

import {FaTrash} from 'react-icons/fa'


export class Basket extends Component {

  render() {
      return(
          <div className='item'>
              <img src={"./img/"+this.props.item.img} />
              <h3>{this.props.item.title}</h3>
              <FaTrash title="Удалить из корзины" className="delete-icon" onClick={()=> this.props.onDelete(this.props.item.id)}/>
              <b>{ new Intl.NumberFormat().format(this.props.item.price) } ₽  x   </b>

              <input type='number' title="Количество"  className='qty' min={1} max={99} maxLength={2} value={this.props.item.qty}
               onChange={ (e) => this.props.onChangeQty (this.props.item.id, e.target.value) }/>
          </div>
      )
    }
}
