import React, {Component} from "react";

export class About extends Component {
    render() {
        return(
            <div>
                About page Про нас
            </div>
        )
    }
}