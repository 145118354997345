//import logo from './logo.svg';
import React  from "react";
import {About} from "./components/About";
import {Contacts} from "./components/Contacts";

import { Routes, Route} from 'react-router-dom';

import Header from "./components/Header";
import Footer from "./components/Footer";
import Shop from "./components/Shop";
import {FaCheck, FaShoppingCart} from "react-icons/fa";

class App extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      basket: [],
      currentItems: [],
      currentCategory: {},
      showFullItem: false,
      fullItem: {},
      items: [
        {
          id:1,
          title: 'Блок дежурного питания',
          desc: 'Питанание контроллера, сетевые фильтры, включение/выключение УМ',
          img: 'bp_sleep.jpg',
          category: 'bp',
          price: '1100'
        },
        {
          id:2,
          title: 'Блок питания УМ',
          desc: 'Блок питания для усилителя мощности',
          img: 'bp_pa.jpg',
          category: 'bp',
          price: '1100'
        },
        {
          id:3,
          title: 'Контроллер Ctrl-Amp 2',
          desc: 'Контроллер управления модулями УМ',
          img: 'controller2.jpg',
          category: 'ctrl',
          price: '2000'
        },
        {
          id:4,
          title: 'Регулятор громкости Никитина',
          desc: 'Модуль мегулятора громкости Никитина на базе бистабильных реле',
          img: 'vc_nic.jpg',
          category: 'vc',
          price: '1500'
        },
        {
          id:5,
          title: 'Регулятор громкости PGA2311',
          desc: 'Модуль гулятора громкости на базе PGA2311 с блоком питания',
          img: 'vc_pga.jpg',
          category: 'vc',
          price: '1500'
        },
        {
          id:6,
          title: 'Регулятор громкости и тембров TDA7439',
          desc: 'Модуль гулятора громкости и тембров с входным селектором на 4x вход на базе TDA7439',
          img: 'vc_tda7439.jpg',
          category: 'vc',
          price: '900'
        },
        {
          id:7,
          title: 'Селектор входов',
          desc: 'Модуль селектора 4x аналоговых входов на базе бистабильных реле',
          img: 'sel_4rca.jpg',
          category: 'sel',
          price: '900'
        },
        {
          id:8,
          title: 'Защита АС',
          desc: 'Модуль защиты с оптронной развязкой и управлением включения/выключения АС',
          img: 'pr_1as.jpg',
          category: 'pr',
          price: '900'
        },
        {
          id:9,
          title: 'Защита АС на 2 грруппы АС',
          desc: 'Модуль защиты с оптронной развязкой и управлением включения/выключения/переключения двух групп АС',
          img: 'pr_2as.jpg',
          category: 'pr',
          price: '1200'
        },
        {
          id:10,
          title: 'Предварительный Усилитель Натали',
          desc: 'Предварительный Усилитель Натали с регулятором тембра Матюшкина',
          img: 'pu_nataly.jpg',
          category: 'pu',
          price: '3500'
        },
        {
          id:11,
          title: 'Усилитель-корректор',
          desc: 'Предварительный Усилитель-корректор с блоком стабилизаторов для Арктур-006',
          img: 'pu_arktur.jpg',
          category: 'pu',
          price: '3500'
        },
        {
          id:12,
          title: 'Усилитель мощности Никитин+',
          desc: 'Усилитель мощности Никитин+',
          img: 'pa_nic.jpg',
          category: 'pa',
          price: '4500'
        },
        {
          id:13,
          title: 'Усилитель мощности Большвик',
          desc: 'Сверхлинейный усилитель мощности',
          img: 'b.JPG',
          category: 'pa',
          price: '10000'
        }

      ]

    }
    this.state.currentCategory='all'
    this.state.currentItems=this.state.items
    this.basketBottom= this.basketBottom.bind(this)
    this.addToBasket= this.addToBasket.bind(this)
    this.deleteFromBasket=this.deleteFromBasket.bind(this)
    this.choseCategory=this.choseCategory.bind(this)
    this.onShowItem=this.onShowItem.bind(this)
    this.changeQty=this.changeQty.bind(this)
  }
  render() {
    return (
      <div className='wrapper'>

        <Header basket={this.state.basket} onDelete={this.deleteFromBasket} onChangeQty={this.changeQty}/>
        <Routes>
          <Route path='/' element={
          <Shop items={this.state.currentItems}
                currentCategory={this.state.currentCategory}
                showFullItem={this.state.showFullItem}
                fullItem={this.state.fullItem}
                choseCategory={this.choseCategory}
                onAdd={this.addToBasket}
                onShowItem={this.onShowItem}
                basketBottom={this.basketBottom}/>
          } />

          <Route path='/about' element={<About />} />
          <Route path='/contacts' element={<Contacts />} />
        </Routes>

        {/*
        <Categories choseCategory={this.choseCategory} />
        <Items items={this.state.currentItems} onAdd={this.addToBasket} onShowItem={this.onShowItem}/>
        { this.state.showFullItem && <ShowFullItem item={this.state.fullItem} onAdd={this.addToBasket} onShowItem={this.onShowItem}/>}
*/}
          <Footer />
      </div>

    );
  }

  onShowItem(item){
    this.setState({fullItem: item})
    this.setState( {showFullItem: !this.state.showFullItem})
  }

  choseCategory ( category){
    this.setState( {currentCategory: category})
    if(category==='all') {
      this.setState({currentItems: this.state.items})
    }
    else {
      this.setState({currentItems: this.state.items.filter(el => el.category === category)})
    }
  }

  deleteFromBasket( id ){
    this.setState({basket: this.state.basket.filter(el=> el.id!==id)})
    //console.log( id )
  }

basketBottom( item ) {
    let inBasket=false
    this.state.basket.forEach( el => { if (el.id===item.id)  inBasket=true;})
    if (!inBasket) {
       return (
           <div className='addToBasket' onClick={() => this.addToBasket( item ) }><FaShoppingCart title="добавить в корзину" /></div>
              )
    } else {
       return( <div className='inBasket'><FaCheck title="Товар уже в корзине" /></div>
             )
    }


  }

  addToBasket( item ) {
    //this.setState({basket: [...this.state.basket, item]}, () => {
    //      console.log(this.state.basket)})
    let inBasket=false
    this.state.basket.forEach( el => { if (el.id===item.id)  inBasket=true;})

    if (!inBasket) {
      let addItem = {
        id: item.id,
        title: item.title,
        img: item.img,
        qty: 1,
        price: item.price}
      this.setState({basket: [...this.state.basket, addItem]} )}
  }

  changeQty ( id, newQty ) {
    this.setState(prevState => ({
      basket: prevState.basket.map(
          el => (el.id === id ? Object.assign(el, { qty: newQty }) : el)
      )
    }));

  }

}



export default App;
