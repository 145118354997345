import React, {Component} from 'react'
import {Categories} from "./categories";
import Items from "./items";
import {ShowFullItem} from "./ShowFullItem";


export class Shop extends Component {
    render() {
        return(
            <div>
            <Categories category={this.props.currentCategory} choseCategory={this.props.choseCategory} />
            <Items items={this.props.items}  onAdd={this.props.onAdd} onShowItem={this.props.onShowItem} basketBottom={this.props.basketBottom} />
            { this.props.showFullItem && <ShowFullItem item={this.props.fullItem}
                                                       onAdd={this.props.onAdd}
                                                       onShowItem={this.props.onShowItem}/>}
                {console.log('shop ' + this.props.currentCategory)}
            </div>

             )
        }
}
export default Shop