import React, {Component} from "react";

export class Contacts extends Component {
    render() {
        return(
            <div>
                Contacts page
            </div>
        )
    }
}