import React, {Component} from 'react'
import {FaCheck, FaShoppingCart} from "react-icons/fa";


export class ShowFullItem extends Component {
    render() {
        return(
            <div className='full-item'>
                <div>
                <img src={"./img/"+this.props.item.img} onClick={ () => this.props.onShowItem(this.props.item)}/>
                <h3 onClick={ () => this.props.onShowItem()}>{this.props.item.title}</h3>
                <p>{this.props.item.desc}</p>
                <b>{this.props.item.price}</b>
                <div className='addToBasket' onClick={() => this.props.onAdd( this.props.item ) }><FaCheck  alt='Добавить в корзину'/><FaShoppingCart /></div>
                </div>
            </div>
        )
    }
}